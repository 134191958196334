<template>
  <div class="coo-box">
    <bannerStatic :num="3" :title="'建立全新的合作关系，实现企业创新共赢'"/>
    <a-anchor class="tab-title" :wrapperClass="'tab'"  :showInkInFixed="false" :bounds="200" @click="handleClick" >
      <a-anchor-link v-for="(items,index) in list" :key="index" :href="'#coo'+items.cooperationId" :class="{'ant-anchor-link-active1':isFrist&&index==0}"  :title="items.cooperationTitle" >
         <IconFont class="iconstyle" :type="items.cooperationIcon" />
      </a-anchor-link>
    </a-anchor>
    <div class="content-box">
       <div class="box" v-for="(item,index) in list" :key="index" :id="'coo'+item.cooperationId" :class="{'minHeight500':(index+1)==list.length}">
         <h3 class="content-title">{{item.cooperationdescription}}<b class="line"></b></h3>
         <p class="content-txt">
           {{item.cooperationContent||''}}
         </p>
       </div>
    </div>
  </div>
</template>
<script>
import bannerStatic from'@/components/BannerStatic/BannerStatic'
import IconFont from'@/components/Icon'
import { getCoo } from '@/api/data'
export default {
  name: 'Cooperation',
  components:{
    bannerStatic,
    IconFont,
  },
  data(){
    return {
      isFrist:true,
      list:[]
    }
  },
   mounted() {
    let _this=this
    window.addEventListener("scroll", this.handleScroll);
    getCoo().then(res => {
        if (res.state) {
          if(res.data.length>0){
            _this.list=res.data
          }
        }
     })
  },
  methods:{
    handleClick(e, link) {
      e.preventDefault();
    },
    handleScroll() {
      let scrollTop =window.pageYOffset ||document.documentElement.scrollTop ||document.body.scrollTop;
      this.scrollTop=scrollTop
      if(scrollTop<600){
          this.isFrist=true
      }else{
        this.isFrist=false
      }
    }
  },
   beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
  },
}
</script>
<style lang="less"  >
 .coo-box{
   .minHeight500{
     height: 460px;
   }
 .tab-title{
    .tab{
      height: 60px;
      overflow: hidden;
      box-shadow: 0px 0px 10px 0px 
    rgba(37, 51, 74, 0.15);
      color: #333333;
      .ant-anchor-link {
          display: inline-block;
          height: 60px;
          line-height: 58px;
          margin:0 50px;
          padding: 0 0px 0 25px;
          position: relative;
          font-size:16px;
          .iconstyle {
              position: absolute;
              top: 20px;
              left: 0;
              font-size: 20px;
          }
      }
    }
    .ant-affix{
      z-index: 999999;
      background: #fff;
      padding-bottom:30px;
    }
  }
  .content-box{
    min-height: 600px;
    background: url(../../assets/img/cooBg.png) no-repeat left 8%, url(../../assets/img/cooBg.png) no-repeat 130% bottom;
    .box{
      width: 960px;
      margin:0 auto;
      padding-top:100px;
      min-height: 200px;
    }
    .content-title{
      font-size: 26px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 1px;
      color: #333333;
      text-align: left;
      position: relative;
      padding-left: 30px;
      .line{
        display: inline-block;
        width: 7px;
        height: 34px;
        background:#73c2ed;
        position: absolute;
        left: 0;
        top:3px;
      }
    }
    .content-txt{
      font-size:16px;
      color:#333;
      text-align: left;
      line-height: 28px;
      padding:20px 0;
    }
  }
.ant-anchor-link-active > .ant-anchor-link-title {
    color: #333333;
}
.ant-anchor-link-active{
    border-bottom: 2px solid #1890ff;
}
.ant-anchor-link-active1{
    border-bottom: 2px solid #1890ff;
}
.ant-anchor-ink-ball.visible{
  display: none !important;
}
a,a:visited,a:link{
  text-decoration: none;
  color: #333333;
}
}
</style>